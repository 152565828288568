import React, { useState } from "react"


let json_current = require("assets/renal.json");

function JSONfix() {

    const [json_data, setjson_data] = useState(json_current);
    const [json_data_original, setjson_data_original] = useState(json_current);
    function FixJSONOptioninjson_data(index) {
        //eg Options:"Increased Na+ |\nIncreased serum pH |\nDecreased pCO2 |\nDecreased HCO3- |\nDecreased K+ |"
        //after conversion: [Increased Na+ , Increased serum pH , Decreased pCO2 , Decreased HCO3- , Decreased K+] // 5 length

        json_current.map((data, index) => {


            json_current[index].Options = json_current[index].Options.replace("/\r?\n|\r/", "");
            const optionarray = json_current[index].Options.split("|");
            optionarray.splice(-1);
            let trimedArr = optionarray.map(str => str.trim());
            json_current[index].Options = trimedArr;


        })
        //console.log(json_current[0]);
        //setjson_data()
    }


    

    function Fix() {
        //eg Options:"Increased Na+ |\nIncreased serum pH |\nDecreased pCO2 |\nDecreased HCO3- |\nDecreased K+ |"
        //after conversion: [Increased Na+ , Increased serum pH , Decreased pCO2 , Decreased HCO3- , Decreased K+] // 5 length




        json_data[0].Options = json_data[0].Options.replace("/\r?\n|\r/", "");

        const optionarray = json_data[0].Options.split("|");
        optionarray.splice(-1);
        // json_data[0].Options = optionarray;
        optionarray.map(data => {
            console.log(data)
        })
        let trimedArr = optionarray.map(str => str.trim());

        console.log(trimedArr)


    }

    return (
        <div>
            <h2>Fix JSON</h2>
            <p>Sample: {JSON.stringify(json_data_original[0])}</p>
            <p>Length: {json_data_original.length}</p>

            <br />
            <p>original: {JSON.stringify(json_data[0].Options)}</p>

            <br />
            <p>Fix: {JSON.stringify(Fix)}</p>
            <p onClick={Fix}>conosle see</p>

            <br />
            <button onClick={FixJSONOptioninjson_data}>Fix this and write to file CLICK ONLY ONCE</button>
            <h2>After Fix</h2><br></br>
            <p>Sample Fix: {JSON.stringify(json_current[0])}</p>

        </div>
    )


}
export default JSONfix